.list-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  padding: 20px;
}

.sidebar {
  padding: 20px;
  border-radius: 8px;
}

.sidebar h2 {
  margin-bottom: 15px;
}

.sidebar ul {
  list-style: none;
}

.sidebar li {
  margin-bottom: 10px;
}

/* Main content styling */
.main-content {
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
}

.main-content h2 {
  margin-bottom: 15px;
}

/* Grid styling */
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
}

.grid-item {
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  font-weight: bold;
}

@media screen and (max-width: 1300px) {
  .list-container {
    grid-template-columns: 1fr 1fr;
  }
  
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .list-container {
    grid-template-columns: 2fr 2fr;
  }
  
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 991px) {
  .brand-item img {
      max-width: 86px !important;
  }
}

@media (max-width: 768px) {
  .list-container {
    grid-template-columns: 1fr 1fr;
  }
  
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 680px) {
  .list-container {
    grid-template-columns: 1fr;
  }
  
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 500px) {
  .list-container {
    grid-template-columns: 1fr;
  }
  
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fc;
  color: #333;
}

.container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  padding: 20px;
  height: 100vh;
}

.sidebar {
  background-color: #2d3748;
  padding: 20px;
  border-radius: 10px;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #fbd38d;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 8px;
}

.sidebar ul {
  list-style: none;
}

.sidebar li {
  margin-bottom: 12px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 6px;
}

.sidebar li:hover {
  background-color: #4a5568;
}

.main-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-content h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #2d3748;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 8px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
}

.grid-item {
  background-color: #4299e1;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.grid-item:hover {
  transform: translateY(-5px);
  background-color: #2b6cb0;
}*/