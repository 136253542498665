// width height
@each $property, $value in $spaces {
  .w-#{$property} {
    width: $value !important;
  }
}

.w {
&-300 {
  width: 300px !important;
}
&-265 {
  width: 265px !important;
}
&-unset {
  width: unset !important;
}
}

@each $property, $value in $spaces {
  .h-#{$property} {
    height: $value !important;
  }
}
.h-unset {
height: unset !important;
}

.max-h {
&-unset {
  max-height: unset !important;
}
&-120 {
  max-height: 120px !important;
}
&-200 {
  max-height: 200px !important;
}
&-260 {
  max-height: 260px !important;
}
&-300 {
  max-height: 300px !important;
}
&-326 {
  max-height: 326px !important;
}
&-400 {
  max-height: 400px !important;
}
&-540 {
  max-height: 540px !important;
}

@media (max-width: 768px) {
  &-540 {
      max-height: 250px !important;
  }
}
}

.max-w {
&-unset {
  max-width: unset !important;
}
&-120 {
  max-width: 120px;
}
&-260 {
  max-width: 260px !important;
}
&-375 {
  max-width: 375px;
}
&-340 {
  max-width: 340px;
}
&-392 {
  max-width: 392px;
}
&-275 {
  max-width: 275px;
}
&-472 {
  max-width: 472px;
}
&-418 {
  max-width: 418px;
}
&-990 {
  max-width: 990px;
}
}


.min-h {
&-485 {
  min-height: 485px;
}
}

.top-n6 {
inset-block-start: -6px;
}

.end-n4 {
inset-inline-end: -6px;
}
