/* ======================== Vendors Two Page css Start ============================= */
.grid-cols-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 24px;
}

.list-view {
    .vendors-two-item {
        display: flex;
    }
}

@media (max-width: 768px) {
    .vendors-two-item {
        margin-bottom: 15px;
    }
    .grid-cols-3 {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        gap: 24px;
    }
}

.custom-tree {
    padding: 10px 0 0 10px;
    max-height: 100vh;
    overflow-x: scroll !important;
    font-size: 13.5px;
}

.custom-tree .p-tree-node {
    padding: 5px;
}

.custom-tree .p-tree-node-content {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-tree .p-tree-node-content :hover {
    background-color: #0771a610;
    color: #0771a6;
    font-weight: 600;
}

.custom-tree .p-checkbox {
    margin-right: 5px;
}

.custom-tree .p-treenode .p-treenode-children {
    margin-left: 20px;
}

/* .custom-tree .p-treenode .p-treenode-children .p-treenode-selectable :before{
    content: '• ';
    color: #0771a6;
    font-weight: 600;
} */