
.bottom-footer {
    padding-block: var(--size-8);
    background-color: #e8eff7;
}

@media (max-width: 768px) {
    .bottom-footer {
        display: none;
        background-color: #fff;
    }
  }