.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    z-index: 1000;
    display: none;
    box-sizing: border-box;
}

.bottom-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    height: 50px;
}

.bottom-nav .nav-link {
    text-align: center;
    padding: 15px;
    color: #0771a6;
    text-decoration: none;
}

@media (max-width: 768px) {
    .bottom-nav {
        display: block;
    }
}