/* ======================= Common Table Css Start ======================= */
.table-card {
    border: 1px solid hsl(var(--white)/.2);
    border-radius: 10px;
    padding: clampCal(20, 36);
}
.table {
    color: hsl(var(--white));
    margin-block-end: 0;
    min-width: max-content;
    &>:not(caption)>*>* {
        border-bottom: 0;
    }
    tr {
        th {
            padding-inline-start: 0;
            padding: 32px;
            &:first-child {
                padding-inline-start: 0;
            }
            &:last-child {
                padding-inline-end: 0;
            }
        }
        th, td {
            text-align: center;
            vertical-align: middle;
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: right;
                padding-inline-end: 0;
            }
        }
    }
    thead {
        tr {
            border-bottom: 1px solid hsl(var(--white)/.2);
            th {
                padding-block-start: 0;
                font-size: clampCal(16, 20);
                font-weight: 400;
                padding-block-end: clampCal(16, 30);
            }
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid hsl(var(--white)/.2);
            &:last-child {
                border-bottom: 0;
                td {
                    padding-block-end: 0;
                }
            }
            td {
                font-size: clampCal(14, 16);
                font-weight: 400;
                // padding: clampCal(16, 20) 0;
            }
        }
    }

    &.style-two {
        color: hsl(var(--heading-color));
        thead tr, tbody tr {
            border-bottom: 1px solid hsl(var(--black)/.1);
        }
        thead tr th {
            padding-block-end: 16px;
            font-size: 15px;
        }
    }
    &.style-three {
        tbody tr {
            border-bottom: 1px solid var(--gray-100);
            &:last-child {
                border: 0;
            }
            td {
                &:first-child {
                    padding-inline-start: 0;
                }
                &:last-child {
                    padding-inline-end: 0;
                }
            }
            td {
                padding: clampCal(24, 40) 24px;
            }
        }
    }
}
/* ======================= Common Table Css End ======================= */