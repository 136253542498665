/* ===================================== Vendor Css Start ================================== */
.vendor-card {
    background-color: var(--main-100);
    -webkit-mask-image: url("~/public/assets/images/shape/box-shape2.png");
    mask-image: url("~/public/assets/images/shape/box-shape2.png");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background: #F4F6E6;
    mask-position: top;
    mask-size: 100% 100%;

    &__logo {
        max-width: 66px;
    }

    &__item {
        max-width: 64px;
        max-height: 64px;
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
    }

    .vendor-card__list {
        &.style-two {
            .vendor-card__item {
                max-width: 88px;
                max-height: 88px;
                border-radius: 8px !important;
            }

            .slick-slide {
                margin: 0 4px !important;
            }
        }
    }

}

.vendor-card-wrapper div[class*="col"] {
    &:nth-child(8n + 1) {
        .vendor-card {
            background: #F4F6E6;
        }
    }

    &:nth-child(8n + 2) {
        .vendor-card {
            background: #E6F6F6;
        }
    }

    &:nth-child(8n + 3) {
        .vendor-card {
            background: #F6E6F6;
        }
    }

    &:nth-child(8n + 4) {
        .vendor-card {
            background: #F8EAE4;
        }
    }

    &:nth-child(8n + 5) {
        .vendor-card {
            background: #F8EAE4;
        }
    }

    &:nth-child(8n + 6) {
        .vendor-card {
            background: #DEE6F3;
        }
    }

    &:nth-child(8n + 7) {
        .vendor-card {
            background: #DAF2DB;
        }
    }

    &:nth-child(8n + 8) {
        .vendor-card {
            background: #EBF1DA;
        }
    }
}

/* ===================================== Vendor Css End ================================== */