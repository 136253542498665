/* Font Family*/
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&family=Quicksand:wght@300..700&display=swap');

$colors: (
  "main": ("50": hsl(var(--main-h), var(--main-s), calc(var(--main-l) + (100% - var(--main-l)) * 0.9)),
    "100": hsl(var(--main-h), var(--main-s), calc(var(--main-l) + (100% - var(--main-l)) * 0.8)),
    "200": hsl(var(--main-h), var(--main-s), calc(var(--main-l) + (100% - var(--main-l)) * 0.7)),
    "300": hsl(var(--main-h), var(--main-s), calc(var(--main-l) + (100% - var(--main-l)) * 0.6)),
    "400": hsl(var(--main-h), var(--main-s), calc(var(--main-l) + (100% - var(--main-l)) * 0.5)),
    "500": hsl(var(--main-h), var(--main-s), calc(var(--main-l) + (100% - var(--main-l)) * 0.4)),
    "600": hsl(var(--main-h), var(--main-s), var(--main-l)), // main theme color
    "700": hsl(var(--main-h), var(--main-s), calc(var(--main-l) - var(--main-l) * 0.1)),
    "800": hsl(var(--main-h), var(--main-s), calc(var(--main-l) - var(--main-l) * 0.2)),
    "900": hsl(var(--main-h), var(--main-s), calc(var(--main-l) - var(--main-l) * 0.3))),
  "main-two": ("50": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) + (100% - var(--main-two-l)) * 0.9)),
    "100": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) + (100% - var(--main-two-l)) * 0.8)),
    "200": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) + (100% - var(--main-two-l)) * 0.7)),
    "300": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) + (100% - var(--main-two-l)) * 0.6)),
    "400": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) + (100% - var(--main-two-l)) * 0.5)),
    "500": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) + (100% - var(--main-two-l)) * 0.4)),
    "600": hsl(var(--main-two-h), var(--main-two-s), var(--main-two-l)), // main-two theme secondary color
    "700": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) - var(--main-two-l) * 0.1)),
    "800": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) - var(--main-two-l) * 0.2)),
    "900": hsl(var(--main-two-h), var(--main-two-s), calc(var(--main-two-l) - var(--main-two-l) * 0.3))),

  "neutral": ("50": #ECF1F9,
    "100": #E6E6E6,
    "200": #CCCCCC,
    "300": #B3B3B3,
    "400": #999999,
    "500": #808080,
    "600": #121535,
    "700": #060710,
    "800": #11132e,
    "900": #1A1A1A,
  ),

  "gray": ("50": #F1F1F1,
    "100": #E6E6E6,
    "200": #CCCCCC,
    "300": #B3B3B3,
    "400": #999999,
    "500": #808080,
    "600": #666666,
    "700": #4D4D4D,
    "800": #333333,
    "900": #1A1A1A,
  ),



  "light": ("50": #F5F6FA,
    "100": #F3F4F6,
    "600": #E4F1FF,
    "700": #374151,
    "800": #1F2937,
  ),

  "primary": ("50": #E4F1FF,
    "100": #BFDCFF,
    "200": #95C7FF,
    "300": #6BB1FF,
    "400": #519FFF,
    "500": #458EFF,
    "600": #487FFF,
    "700": #486CEA,
    "800": #4759D6,
    "900": #4536B6),

  "danger": ("50": #FEF2F2,
    "100": #FEE2E2,
    "200": #FECACA,
    "300": #FCA5A5,
    "400": #F87171,
    "500": #EF4444,
    "600": #DC2626,
    "700": #B91C1C,
    "800": #991B1B,
    "900": #7F1D1D),

  "success": ("50": #F0FDF4,
    "100": #DCFCE7,
    "200": #BBF7D0,
    "300": #86EFAC,
    "400": #4ADE80,
    "500": #22C55E,
    "600": #27AE60,
    "700": #15803D,
    "800": #166534,
    "900": #14532D),

  "warning": ("50": #FEFCE8,
    "100": #FEF9C3,
    "200": #FEF08A,
    "300": #FDE047,
    "400": #FACC15,
    "500": #EAB308,
    "600": #FF9F29,
    "700": #f39016,
    "800": #e58209,
    "900": #d77907),

  "info": ("50": #EFF6FF,
    "100": #DBEAFE,
    "200": #BFDBFE,
    "300": #93C5FD,
    "400": #60A5FA,
    "500": #3B82F6,
    "600": #2563EB,
    "700": #1D4ED8,
    "800": #1E40AF,
    "900": #1E3A8A),

  "tertiary": ("50": #d1c8ff,
    "100": #c2b6ff,
    "600": #3C23B5,
  ),
);

// Space
$spaces: (
  "2": #{rem(2px)},
  "3": #{rem(3px)},
  "4": #{rem(4px)},
  "5": #{rem(5px)},
  "6": #{rem(6px)},
  "7": #{rem(7px)},
  "8": #{rem(8px)},
  "9": #{rem(9px)},
  "10": #{rem(10px)},
  "11": #{rem(11px)},
  "12": #{rem(12px)},
  "13": #{rem(13px)},
  "14": #{rem(14px)},
  "16": #{rem(16px)},
  "18": #{rem(18px)},
  "20": #{rem(20px)},
  "22": #{rem(22px)},
  "24": #{rem(24px)},
  "26": #{rem(26px)},
  "28": #{rem(28px)},
  "30": #{rem(30px)},
  "32": #{rem(32px)},
  "36": #{rem(32px)},
  "40": #{rem(40px)},
  "44": #{rem(44px)},
  "48": #{rem(48px)},
  "52": #{rem(52px)},
  "56": #{rem(56px)},
  "60": #{rem(60px)},
  "64": #{rem(64px)},
  "68": #{rem(68px)},
  "72": #{rem(72px)},
  "76": #{rem(76px)},
  "80": #{rem(80px)},
  "90": #{rem(90px)},
  "110": #{rem(110px)},
  "120": #{rem(120px)},
  "144": #{rem(144px)},
  "160": #{rem(160px)},
);

$font-sizes: (
  "xs": #{rem(12px)},
  "sm": #{rem(14px)},
  "md": #{rem(16px)},
  "lg": #{rem(18px)},
  "xl": #{rem(20px)},
  "2xl": #{rem(24px)},
  "3xl": #{rem(30px)},
  "4xl": #{rem(36px)},
  "5xl": #{rem(48px)},
  "6xl": #{rem(60px)},
);

/* ========================= Variable Css Start ======================== */
:root {

  /* Font Family Variable */
  --heading-font: "Quicksand", sans-serif;
  --heading-font-two: "Exo", sans-serif;
  --body-font: "Inter", sans-serif;

  /* Font Size Variable Start */
  --heading-one: clamp(2.25rem, -0.0733rem + 6.3692vw, 4.5rem); // Min:36px - Max:72px
  --heading-two: clamp(2rem, 0.7133rem + 2.8846vw, 3.5rem); // Min: 32px - Max: 56px
  --heading-three: clamp(1.75rem, 0.3353rem + 2.1661vw, 3rem); // Min: 28px - Max: 48px
  --heading-four: clamp(1.5rem, 0.5569rem + 1.444vw, 2.5rem); // Min: 24px - Max: 40px
  --heading-five: clamp(1.25rem, 1.2rem + 0.722vw, 2.25rem); // Min: 20px - Max: 36px
  --heading-six: clamp(1.125rem, 0.769rem + 0.6813vw, 1.5rem); // Min: 18px - Max-24px
  /* Font Size End */

  /* template main color */
  --main-h: 210;
  --main-s: 100%;
  --main-l: 37%;
  --main: var(--main-h) var(--main-s) var(--main-l);

  /* template main color */
  --main-two-h: 25;
  --main-two-s: 100%;
  --main-two-l: 49%;
  --main-two: var(--main-two-h) var(--main-two-s) var(--main-two-l);

  /* White Color */
  --white: 0 0% 100%;
  --black: 235 49% 14%;
  --light: 0 0% 51%;

  --heading-color: var(--black);
  --body-color: var(--light);
  --body-bg: var(--gray);
  --border-color: 236 13% 77%;

  --bg-color-one: #e8eff7;
  --bg-color-two: #FFFBF4;
  --bg-color-three: #F1F1F1;
  --neutral: #121535;

  --translate-x-middle: translateX(-50%);
  --translate-y-middle: translateY(-50%);

  // spacing
  @each $property, $value in $spaces {
    --size-#{$property}: #{$value};
  }

  // Colors
  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      --#{$color}-#{$shade}: #{$value};
    }
  }

  // font sizes
  @each $property, $value in $font-sizes {
    --font-#{$property}: #{$value};
  }
}

/* ========================= Variable Css End ======================== */