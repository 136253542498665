.pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 0;
}

@media(max-width: 768px) {
  .pagination {
    margin-bottom: 90px;
  }
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 2px;
  min-height: 30px;
  min-width: 30px;
  text-align: center;
  line-height: 30px;
  color: #0771a6;
  text-decoration: none;
  border-radius: 20px;
  border: none;
}

.page-item a.page-link:hover {
  background-color: #fff;
}

.page-item.active .page-link {
  font-weight: 700;
  color: #ffffff;
  background-color: #0771a6;
  border: none;
}

.page-item.disabled .page-link {
  background-color: #fff;
  pointer-events: none;
  cursor: auto;
  border: none;
}